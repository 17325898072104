export default function () {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.554 19.267C17.8089 19.443 18.1374 19.487 18.4303 19.379C18.7232 19.2701 18.9386 19.0262 19.0035 18.7306C19.6914 15.5783 21.3602 7.59937 21.9864 4.73185C22.0339 4.51573 21.9548 4.29111 21.7806 4.14677C21.6064 4.00243 21.365 3.96075 21.1497 4.03871C17.8303 5.23666 7.60771 8.9764 3.42939 10.4839C3.16419 10.5796 2.99161 10.8281 3.00031 11.1006C3.00981 11.3738 3.19823 11.6108 3.46976 11.6903C5.3436 12.2368 7.80325 12.9971 7.80325 12.9971C7.80325 12.9971 8.95272 16.3818 9.552 18.1031C9.62721 18.3192 9.80058 18.489 10.0294 18.5477C10.2574 18.6055 10.5012 18.5446 10.6714 18.3879C11.634 17.5018 13.1223 16.1317 13.1223 16.1317C13.1223 16.1317 15.9501 18.1532 17.554 19.267ZM8.83793 12.5695L10.1671 16.8441L10.4624 14.1372C10.4624 14.1372 15.5978 9.62091 18.5253 7.04671C18.6108 6.97106 18.6227 6.84448 18.5515 6.75571C18.481 6.66695 18.3512 6.64611 18.2538 6.70631C14.8608 8.81894 8.83793 12.5695 8.83793 12.5695Z"
        fill="currentColor"
      />
    </svg>
  )
}
