export default function () {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3125 1.24951C7.51656 1.24951 5.25 3.51607 5.25 6.31201V9.29497C5.25 12.3074 7.69208 14.7495 10.7045 14.7495C11.0058 14.7495 11.25 14.9937 11.25 15.295V17.8051C11.25 18.0505 11.051 18.2495 10.8056 18.2495H10.5227C8.71524 18.2495 7.25 19.7148 7.25 21.5223C7.25 22.2001 7.79946 22.7495 8.47726 22.7495H15.5227C16.2005 22.7495 16.75 22.2001 16.75 21.5223C16.75 19.7148 15.2847 18.2495 13.4773 18.2495H13.1944C12.949 18.2495 12.75 18.0505 12.75 17.8051V15.295C12.75 14.9937 12.9942 14.7495 13.2954 14.7495C16.3079 14.7495 18.75 12.3074 18.75 9.29497V6.31201C18.75 3.51607 16.4834 1.24951 13.6875 1.24951H10.3125Z"
        fill="currentColor"
      />
      <path
        d="M4 5.59951C4 5.26813 3.73136 4.99951 3.4 4.99951H3C1.89543 4.99951 1 5.89493 1 6.99951V8.33283C1 9.80559 2.1939 10.9995 3.66666 10.9995C3.85076 10.9995 4 10.8503 4 10.6662V5.59951Z"
        fill="currentColor"
      />
      <path
        d="M20 5.59951C20 5.26813 20.2686 4.99951 20.6 4.99951H21C22.1046 4.99951 23 5.89495 23 6.99951V8.33285C23 9.80561 21.806 10.9995 20.3334 10.9995C20.1492 10.9995 20 10.8503 20 10.6662V5.59951Z"
        fill="currentColor"
      />
    </svg>
  )
}
