export default function ({
  size,
  onClick,
}: {
  size: number
  onClick: () => void
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="cursor-pointer opacity-40 hover:opacity-80 active:opacity-100 inline-block transition-opacity"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 18.5C14.4183 18.5 18 14.9183 18 10.5C18 6.08172 14.4183 2.5 10 2.5C5.58172 2.5 2 6.08172 2 10.5C2 14.9183 5.58172 18.5 10 18.5ZM9.99822 6.98847C9.4783 6.98847 9.03894 7.34404 8.9147 7.82676C8.77881 8.35471 8.24067 8.67254 7.71272 8.53665C7.18478 8.40077 6.86695 7.86263 7.00283 7.33468C7.34612 6.00091 8.5557 5.01429 9.99822 5.01429C11.7064 5.01429 13.0911 6.39901 13.0911 8.10717C13.0911 9.47087 12.3456 10.1839 11.8233 10.6713C11.3113 11.1493 11.0521 11.3951 10.9754 11.9319C10.8983 12.4716 10.3983 12.8466 9.85864 12.7695C9.31896 12.6924 8.94397 12.1924 9.02107 11.6527C9.19822 10.4126 9.92299 9.74102 10.4023 9.29682L10.4763 9.22812C10.9411 8.7943 11.1169 8.58603 11.1169 8.10716C11.1169 7.48933 10.6161 6.98847 9.99822 6.98847ZM11.1429 14.8429C11.1429 15.474 10.6312 15.9857 10 15.9857C9.36882 15.9857 8.85714 15.474 8.85714 14.8429C8.85714 14.2117 9.36882 13.7 10 13.7C10.6312 13.7 11.1429 14.2117 11.1429 14.8429Z"
        fill="white"
      />
    </svg>
  )
}
