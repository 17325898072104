export default function ({
  size,
  withBackground,
  className,
}: {
  size: number
  withBackground?: boolean
  className?: string
}) {
  const background = withBackground ? 'p-1 rounded-lg bg-secondary ' : ''

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={background + className}
    >
      <circle cx="26.1055" cy="26.104" r="10.0435" fill="currentColor" />
      <path
        d="M40.542 24.7398C40.8544 25.0522 41.3637 25.0541 41.6503 24.7179C42.3303 23.9198 42.8823 23.0189 43.2848 22.0471C43.7896 20.8286 44.0494 19.5226 44.0494 18.2036C44.0494 16.8847 43.7896 15.5787 43.2848 14.3602C42.7801 13.1416 42.0403 12.0344 41.1077 11.1018C40.1751 10.1692 39.0679 9.4294 37.8493 8.92467C36.6308 8.41993 35.3248 8.16015 34.0059 8.16015C32.6869 8.16015 31.3809 8.41993 30.1624 8.92467C29.1906 9.32718 28.2897 9.87918 27.4916 10.5592C27.1554 10.8458 27.1573 11.3551 27.4697 11.6675L34.0059 18.2036L40.542 24.7398Z"
        fill="currentColor"
      />
      <path
        d="M11.6689 27.4702C11.3565 27.1578 10.8472 27.1558 10.5607 27.4921C9.88061 28.2902 9.32861 29.1911 8.9261 30.1628C8.42137 31.3814 8.16158 32.6874 8.16158 34.0063C8.16158 35.3252 8.42137 36.6313 8.9261 37.8498C9.43083 39.0683 10.1706 40.1755 11.1033 41.1081C12.0359 42.0408 13.1431 42.7806 14.3616 43.2853C15.5801 43.79 16.8861 44.0498 18.2051 44.0498C19.524 44.0498 20.83 43.79 22.0486 43.2853C23.0203 42.8828 23.9212 42.3308 24.7193 41.6507C25.0556 41.3641 25.0536 40.8549 24.7412 40.5425L18.2051 34.0063L11.6689 27.4702Z"
        fill="currentColor"
      />
      <path
        d="M24.7398 11.667C25.0522 11.3546 25.0541 10.8453 24.7179 10.5587C23.9198 9.87865 23.0189 9.32665 22.0471 8.92414C20.8286 8.41941 19.5226 8.15963 18.2036 8.15963C16.8847 8.15963 15.5787 8.41941 14.3602 8.92414C13.1416 9.42888 12.0344 10.1687 11.1018 11.1013C10.1692 12.0339 9.4294 13.1411 8.92467 14.3596C8.41993 15.5782 8.16015 16.8842 8.16015 18.2031C8.16015 19.5221 8.41993 20.8281 8.92467 22.0466C9.32718 23.0183 9.87918 23.9193 10.5592 24.7173C10.8458 25.0536 11.3551 25.0517 11.6675 24.7393L18.2036 18.2031L24.7398 11.667Z"
        fill="currentColor"
      />
      <path
        d="M27.4712 40.543C27.1587 40.8554 27.1568 41.3647 27.4931 41.6512C28.2911 42.3313 29.1921 42.8833 30.1638 43.2858C31.3823 43.7905 32.6884 44.0503 34.0073 44.0503C35.3262 44.0503 36.6322 43.7905 37.8508 43.2858C39.0693 42.7811 40.1765 42.0413 41.1091 41.1087C42.0417 40.176 42.7815 39.0688 43.2863 37.8503C43.791 36.6318 44.0508 35.3258 44.0508 34.0068C44.0508 32.6879 43.791 31.3819 43.2863 30.1634C42.8838 29.1916 42.3318 28.2907 41.6517 27.4926C41.3651 27.1563 40.8558 27.1583 40.5434 27.4707L34.0073 34.0068L27.4712 40.543Z"
        fill="currentColor"
      />
    </svg>
  )
}
