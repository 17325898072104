import { ClassNameProp } from 'type/Props'

export default function ({ className }: ClassNameProp) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.62435 4.5C6.62435 3.46447 7.46381 2.625 8.49935 2.625H8.57958C9.44302 2.625 10.2042 3.19137 10.4523 4.0184L11.1217 6.24967H7.82395C7.12215 5.97859 6.62435 5.29743 6.62435 4.5ZM5.90971 6.24967C5.57175 5.75044 5.37435 5.14827 5.37435 4.5C5.37435 2.77411 6.77346 1.375 8.49935 1.375H8.57958C9.99503 1.375 11.2429 2.30345 11.6496 3.65921L11.9993 4.82494L12.3491 3.65921C12.7558 2.30345 14.0037 1.375 15.4191 1.375H15.4993C17.2252 1.375 18.6243 2.77411 18.6243 4.5C18.6243 5.14827 18.427 5.75044 18.089 6.24967H20.5C21.7426 6.24967 22.75 7.25703 22.75 8.49967C22.75 9.74232 21.7426 10.7497 20.5 10.7497H3.5C2.25736 10.7497 1.25 9.74232 1.25 8.49967C1.25 7.25703 2.25736 6.24967 3.5 6.24967H5.90971ZM16.1747 6.24967C16.8765 5.97859 17.3743 5.29743 17.3743 4.5C17.3743 3.46447 16.5349 2.625 15.4993 2.625H15.4191C14.5557 2.625 13.7945 3.19137 13.5463 4.0184L12.877 6.24967H16.1747ZM11.2409 12.5797C11.25 12.6225 11.25 12.6742 11.25 12.7775V16.2497C11.25 16.6639 11.5858 16.9997 12 16.9997C12.4142 16.9997 12.75 16.6639 12.75 16.2497V12.7775C12.75 12.6742 12.75 12.6225 12.7591 12.5797C12.7933 12.4187 12.9191 12.293 13.08 12.2588C13.1229 12.2497 13.1745 12.2497 13.2778 12.2497H20.0833C20.3167 12.2497 20.4334 12.2497 20.5225 12.2951C20.6009 12.335 20.6646 12.3988 20.7046 12.4772C20.75 12.5663 20.75 12.683 20.75 12.9163V17.4163C20.75 19.2832 20.75 20.2166 20.3867 20.9296C20.0671 21.5569 19.5572 22.0668 18.93 22.3864C18.2169 22.7497 17.2835 22.7497 15.4167 22.7497H8.58333C6.71649 22.7497 5.78307 22.7497 5.07003 22.3864C4.44282 22.0668 3.93289 21.5569 3.61331 20.9296C3.25 20.2166 3.25 19.2832 3.25 17.4163V12.9163C3.25 12.683 3.25 12.5663 3.29541 12.4772C3.33536 12.3988 3.3991 12.335 3.4775 12.2951C3.56663 12.2497 3.68331 12.2497 3.91667 12.2497H10.7222C10.8255 12.2497 10.8771 12.2497 10.92 12.2588C11.0809 12.293 11.2067 12.4187 11.2409 12.5797Z"
        fill="currentColor"
      />
    </svg>
  )
}
