export default function () {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.76735 10.9229C7.0449 10.9882 7.36054 11.0273 7.71429 11.0404V12H8.26122V11.0208C8.84354 10.9555 9.27891 10.763 9.56735 10.4431C9.85578 10.1232 10 9.72501 10 9.24847C10 8.86332 9.91565 8.55324 9.74694 8.31824C9.58367 8.08323 9.36871 7.89066 9.10204 7.74051C8.83537 7.58384 8.5551 7.44023 8.26122 7.30967V5.89963C8.4517 5.91922 8.65578 5.95838 8.87347 6.01714C9.0966 6.07589 9.31701 6.16075 9.53469 6.27173L9.9102 5.15545C9.65442 5.01836 9.39048 4.91391 9.11837 4.84211C8.8517 4.7703 8.56599 4.72787 8.26122 4.71481V4H7.71429V4.73439C7.37687 4.76703 7.07755 4.85516 6.81633 4.99878C6.56054 5.14239 6.35918 5.33497 6.21224 5.5765C6.07075 5.81151 6 6.09221 6 6.4186C6 6.66014 6.02993 6.87556 6.0898 7.06487C6.14966 7.24765 6.2449 7.41738 6.37551 7.57405C6.51156 7.72419 6.68844 7.86781 6.90612 8.0049C7.12925 8.14198 7.39864 8.2856 7.71429 8.43574V9.91432C7.43129 9.89474 7.13197 9.83272 6.81633 9.72827C6.50068 9.6173 6.23129 9.49653 6.00816 9.36597V10.6291C6.23673 10.7532 6.4898 10.8511 6.76735 10.9229ZM8.66939 9.68911C8.58231 9.7805 8.44626 9.84578 8.26122 9.88494V8.69033C8.38095 8.75561 8.47891 8.82089 8.5551 8.88617C8.63673 8.94492 8.6966 9.0102 8.73469 9.08201C8.77823 9.14729 8.8 9.22562 8.8 9.31701C8.8 9.46716 8.75646 9.59119 8.66939 9.68911ZM7.43673 6.01714C7.51293 5.97144 7.60544 5.9388 7.71429 5.91922V7.04529C7.59456 6.98001 7.4966 6.91799 7.42041 6.85924C7.34422 6.79396 7.28708 6.72868 7.24898 6.6634C7.21633 6.5916 7.2 6.51 7.2 6.4186C7.2 6.32721 7.21905 6.24888 7.25714 6.1836C7.30068 6.11179 7.36054 6.0563 7.43673 6.01714Z"
        fill="currentColor"
      />
    </svg>
  )
}
