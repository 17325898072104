export default function () {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.98005 1.79497C5.04961 1.25 6.44974 1.25 9.25 1.25H14.75C17.5503 1.25 18.9504 1.25 20.02 1.79497C20.9608 2.27433 21.7257 3.03924 22.205 3.98005C22.75 5.04961 22.75 6.44974 22.75 9.25V14.75C22.75 17.5503 22.75 18.9504 22.205 20.02C21.7257 20.9608 20.9608 21.7257 20.02 22.205C18.9504 22.75 17.5503 22.75 14.75 22.75H9.25C6.44974 22.75 5.04961 22.75 3.98005 22.205C3.03924 21.7257 2.27433 20.9608 1.79497 20.02C1.25 18.9504 1.25 17.5503 1.25 14.75V9.25C1.25 6.44974 1.25 5.04961 1.79497 3.98005C2.27433 3.03924 3.03924 2.27433 3.98005 1.79497ZM12 17.75C11.3096 17.75 10.75 17.1904 10.75 16.5C10.75 15.8096 11.3096 15.25 12 15.25C12.6904 15.25 13.25 15.8096 13.25 16.5C13.25 17.1904 12.6904 17.75 12 17.75ZM7.5 13.25C6.80964 13.25 6.25 12.6904 6.25 12C6.25 11.3096 6.80964 10.75 7.5 10.75C8.19036 10.75 8.75 11.3096 8.75 12C8.75 12.6904 8.19036 13.25 7.5 13.25ZM12 13.25C11.3096 13.25 10.75 12.6904 10.75 12C10.75 11.3096 11.3096 10.75 12 10.75C12.6904 10.75 13.25 11.3096 13.25 12C13.25 12.6904 12.6904 13.25 12 13.25ZM16.5 13.25C15.8096 13.25 15.25 12.6904 15.25 12C15.25 11.3096 15.8096 10.75 16.5 10.75C17.1904 10.75 17.75 11.3096 17.75 12C17.75 12.6904 17.1904 13.25 16.5 13.25ZM12 8.75C11.3096 8.75 10.75 8.19036 10.75 7.5C10.75 6.80964 11.3096 6.25 12 6.25C12.6904 6.25 13.25 6.80964 13.25 7.5C13.25 8.19036 12.6904 8.75 12 8.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
